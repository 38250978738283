import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ArrowDownTrayIcon, SignalIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { Button, Loading, Modal, RevisionStateBadge } from '@src/components';
import { RenderStateCircle } from '@src/components/render/RenderStateCircle';
import { useGetRenderDetails } from '@src/hooks';
import { AnyVideoRevision, RenderState, RevisionRender, RevisionState } from '@src/models';
import * as routes from '@src/routes';

type VideoPreviewModalProps = {
  showPreviewModal: boolean;
  setShowPreviewModal: (showModal: boolean) => void;
  currentRevision: AnyVideoRevision;
  clonedRevision?: AnyVideoRevision;
};

const VideoPreviewModalContent = ({
  setShowPreviewModal,
  currentRevision,
  clonedRevision
}: Omit<VideoPreviewModalProps, 'showPreviewModal'>) => {
  const { t } = useTranslation();
  const [selectedRender, setSelectedRender] = useState<RevisionRender | undefined>(
    currentRevision.finalRender ||
      currentRevision.previewRender ||
      clonedRevision?.finalRender ||
      clonedRevision?.previewRender
  );

  useEffect(() => {
    setSelectedRender(
      currentRevision.finalRender ||
        currentRevision.previewRender ||
        clonedRevision?.finalRender ||
        clonedRevision?.previewRender
    );
  }, [currentRevision, clonedRevision]);

  const renderId = selectedRender?.renderId;
  const { isLoading, data: render } = useGetRenderDetails(renderId);

  return (
    <>
      <div className="flex flex-col divide-y divide-gray-100 sm:flex-row sm:divide-x">
        <nav className="flex  flex-col gap-2 whitespace-nowrap bg-gray-50 px-4 py-6" aria-label="Sidebar">
          <div className="text-xs font-semibold leading-6 text-gray-400">{t('general.common.renders')}</div>
          <ul className="-mx-2 mt-2 w-full space-y-1 sm:w-52">
            <ListItem
              selectedRender={selectedRender}
              setSelectedRender={setSelectedRender}
              t={t}
              currentRevision={currentRevision}
            />
            {clonedRevision && (
              <ListItem
                selectedRender={selectedRender}
                setSelectedRender={setSelectedRender}
                t={t}
                clonedRevision={clonedRevision}
              />
            )}
          </ul>
        </nav>
        {isLoading && <Loading />}

        <div className="relative flex max-h-vh-300 min-h-[370px] w-full items-center justify-center p-6">
          {!isLoading && render && render.state === RenderState.DONE && render?.output ? (
            <video
              poster={render.thumbnailUris ? render.thumbnailUris[0] : undefined}
              controls
              src={render.output}
              className="max-h-vh-300 w-full rounded-md"
            />
          ) : currentRevision.state === RevisionState.PROCESSING ||
            (!isLoading &&
              render &&
              [RenderState.PENDING, RenderState.THROTTLED, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(
                render.state
              )) ? (
            <div className="relative flex h-auto w-full flex-col justify-center rounded-md text-center text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <SignalIcon className="mx-auto h-8 w-8 animate-spin" />
              <span className="mt-2 block text-sm text-gray-500">{t('components.common.pendingRender')}</span>
            </div>
          ) : (
            <div className="flex max-h-[300px] items-center justify-center rounded-md bg-white">
              <img
                className="h-full w-full object-cover"
                src="/assets/no-preview-available.png"
                alt="Preview not available."
              />
            </div>
          )}
          <div className="absolute right-10 top-10 z-20 flex gap-2">
            {selectedRender?.renderId && (
              <Link
                to={generatePath(routes.RENDER_DETAILS, {
                  id: selectedRender?.renderId
                })}
              >
                <Button title={t('components.common.renderDetails')} icon={<VideoCameraIcon />} secondary />
              </Link>
            )}
            {!isLoading && render && render.state === RenderState.DONE && render.output && !render.expired && (
              <a href={render.output} target="_blank" rel="noreferrer" download>
                <Button title={t('components.common.preview')} icon={<ArrowDownTrayIcon />} secondary />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2 p-3">
        <Button secondary onClick={() => setShowPreviewModal(false)}>
          {t('general.action.dismiss')}
        </Button>
      </div>
    </>
  );
};

const ListItem = ({
  selectedRender,
  setSelectedRender,
  currentRevision,
  clonedRevision,
  t
}: {
  selectedRender?: RevisionRender;
  setSelectedRender: (selectedRender?: RevisionRender) => void;
  currentRevision?: AnyVideoRevision;
  clonedRevision?: AnyVideoRevision;
  t: TFunction;
}) => {
  return (
    <li>
      <div className="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 disabled:cursor-not-allowed disabled:opacity-50">
        <div className="flex w-full items-center justify-between">
          <span>
            {currentRevision && t('components.article.ArticleVideo.previewModal.currentRevision')}
            {clonedRevision && t('components.article.ArticleVideo.previewModal.clonedRevision')}
          </span>
          {currentRevision && <RevisionStateBadge revision={currentRevision} />}
          {clonedRevision && <RevisionStateBadge revision={clonedRevision} />}
        </div>
      </div>
      {currentRevision && !currentRevision?.finalRender && !currentRevision?.previewRender && (
        <p className="pointer-events-none w-full py-1 pl-8 pr-6 text-xs font-medium text-gray-400">
          {t('components.render.EmptyRenders.title')}
        </p>
      )}
      {currentRevision?.finalRender && (
        <SubItem
          selectedRender={selectedRender}
          setSelectedRender={setSelectedRender}
          revisionRender={currentRevision.finalRender}
          t={t}
          renderType="final"
        />
      )}
      {currentRevision?.previewRender && (
        <SubItem
          selectedRender={selectedRender}
          setSelectedRender={setSelectedRender}
          revisionRender={currentRevision.previewRender}
          t={t}
          renderType="preview"
        />
      )}
      {clonedRevision?.finalRender && (
        <SubItem
          selectedRender={selectedRender}
          setSelectedRender={setSelectedRender}
          revisionRender={clonedRevision.finalRender}
          t={t}
          renderType="final"
        />
      )}
      {clonedRevision?.previewRender && (
        <SubItem
          selectedRender={selectedRender}
          setSelectedRender={setSelectedRender}
          revisionRender={clonedRevision.previewRender}
          t={t}
          renderType="preview"
        />
      )}
    </li>
  );
};

const SubItem = ({
  selectedRender,
  setSelectedRender,
  revisionRender,
  t,
  renderType
}: {
  selectedRender?: RevisionRender;
  setSelectedRender: (selectedRender?: RevisionRender) => void;
  revisionRender: RevisionRender;
  t: TFunction;
  renderType: string;
}) => {
  return (
    <button
      type="button"
      onClick={() => setSelectedRender(revisionRender)}
      className={classNames(
        selectedRender?.renderId === revisionRender.renderId && 'bg-gray-100 text-indigo-600',
        'flex w-full items-center justify-between gap-x-3 rounded-md py-1 pl-2 pr-6 text-xs font-medium text-gray-500 hover:bg-gray-100'
      )}
    >
      <div className="flex items-center gap-2">
        <VideoCameraIcon className="h-4 w-4" />
        {t('components.article.ArticleVideo.previewModal.render', { context: renderType })}
      </div>
      <RenderStateCircle state={revisionRender.renderState} />
    </button>
  );
};

export const VideoPreviewModal = ({
  showPreviewModal,
  setShowPreviewModal,
  currentRevision,
  clonedRevision
}: VideoPreviewModalProps) => {
  return (
    <Modal visible={showPreviewModal} close={() => setShowPreviewModal(false)} size="large" removeOuterPadding>
      <VideoPreviewModalContent
        setShowPreviewModal={setShowPreviewModal}
        currentRevision={currentRevision}
        clonedRevision={clonedRevision}
      />
    </Modal>
  );
};
