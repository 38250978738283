import { Asset, ModelBaseWithDates, Parametrization, Scripting } from '..';

export * from './asset';

export type Render = ModelBaseWithDates & {
  projectId: string;
  templateId: string;
  createdBy?: string;
  submittedDate: string;
  expirationDate?: string;
  state: RenderState;
  retried?: boolean;
  expired?: boolean;
  projectName: string;
  templateName: string;
  parametrizationResults: ParametrizationResult[];
  compositionName: string;
  parameters: RenderParameters;
  outputFormat?: OutputFormat;
  webhook: Webhook;
  options?: ExtraOptions;
  output?: string;
  outputWatermark?: string;
  projectZipUrl?: string;
  attributes?: Record<string, unknown>;
  thumbnailUris?: string[];
  error: { [key: string]: string | object };
  webhookResponseCode: number;
  webhookDelivery: WebhookDelivery;
  publicDesign?: boolean;
};

export enum RenderState {
  PENDING = 'PENDING',
  THROTTLED = 'THROTTLED',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  CANCELLED = 'CANCELLED'
}

export type ParametrizationResult = {
  parametrization: Parametrization;
  assets: Asset[];
  mandatoryNotResolved: boolean;
  errorMessage?: string;
  fatalError: boolean;
  scripting?: Scripting;
};

export type RenderParameters = { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any

export type OutputFormat = OutputFormatDto & {
  ext: string;
  contentType: string;
  encodingNeeded: boolean;
};

export enum OutputFormatType {
  'MP4' = 'MP4',
  'MP4_H265' = 'MP4_H265',
  'GIF' = 'GIF',
  'MOV' = 'MOV'
  // 'OGG' = 'OGG',
  // 'WEBM' = 'WEBM'
}

export enum OutputModule {
  H_264 = 'H_264',
  HQ = 'HQ',
  HQ_ALPHA = 'HQ_ALPHA'
}

export enum SettingsTemplate {
  BEST_SETTINGS = 'BEST_SETTINGS',
  DRAFT = 'DRAFT'
}

export type PostEncode =
  | {
      type: 'default';
    }
  | {
      type: 'none';
    }
  | {
      type: 'custom';
      encodingFormat?: OutputFormatType;
      encodingParamsLine?: string;
    }
  | {
      type: 'smallest';
    }
  | {
      type: 'captions';
      srtFileUrl: string;
      captionsStyle?: CaptionsStyle;
      captionsPosition?: CaptionsPosition;
      baseEncoding?: PostEncode;
    };

export enum CaptionsStyle {
  BASIC = 'BASIC',
  BASIC_WITH_STROKE_AND_SHADOW = 'BASIC_WITH_STROKE_AND_SHADOW',
  BASIC_WITH_SHADOW = 'BASIC_WITH_SHADOW',
  POPPINS_WHITE = 'POPPINS_WHITE',
  POPPINS_WHITE_VERTICAL = 'POPPINS_WHITE_VERTICAL'
}

export enum CaptionsPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  CENTER = 'CENTER'
}

export type OutputFormatDto = {
  format?: OutputFormatType; // deprecated
  encodingParams?: { [key: string]: string }; // deprecated
  attachment?: boolean;
  attachmentFileName?: string;
  outputModule?: OutputModule;
  settingsTemplate?: SettingsTemplate;
  postEncoding?: PostEncode;
};

export type Webhook = {
  url: string;
  passthrough?: string;
  onFailure?: boolean;
  onInvalid?: boolean;
};

export type ExtraOptions = {
  thumbnails?: {
    frequencySeconds?: number;
    atSeconds?: number[];
  };
  integrations?: {
    skipAll?: boolean;
    passthrough?: string;
  };
  watermark?: {
    url?: string;
    encodingParamsLine?: string;
  };
  projectFiles?: {
    uploadEnabled?: boolean;
  };
  captions?: {
    srtFileUrl?: string;
    captionsStyle?: CaptionsStyle;
    captionsPosition?: CaptionsPosition;
  };
};

export type Delivery = {
  manual: boolean;
  date: string;
  responseCode: number;
  success: boolean;
};

export enum WebhookDeliveryStatus {
  'PENDING' = 'PENDING',
  'RETRYING' = 'RETRYING',
  'IGNORED' = 'IGNORED',
  'FAILURE' = 'FAILURE',
  'SUCCESS' = 'SUCCESS'
}

export type WebhookDelivery = {
  status: WebhookDeliveryStatus;
  deliveries: Delivery[];
};

export type ProjectRenderDto = AbstractRenderDto & {
  projectId: string;
  templateId?: string;
};

export type RenderOptionsDto = {
  outputFormat?: OutputFormatDto;
  webhook?: Webhook;
  options?: ExtraOptions;
};

export type AbstractRenderDto = RenderOptionsDto & {
  parameters?: RenderParameters;
  attributes?: Record<string, unknown>;
};

export type RenderOptions = RenderOptionsDto;

export type RenderStatsDto = {
  renders: QuickStats;
  webhookCalls: QuickStats;
};

type QuickStats = {
  total: number;
  failed: number;
};

export enum AutoCreateTemplateType {
  all = 'all',
  prefix = 'prefix'
}

export type AutoCreateTemplateDto<T extends AutoCreateTemplateType> = {
  type: T;
};

export type AllAutoCreateTemplateDto = AutoCreateTemplateDto<AutoCreateTemplateType.all> & {
  allLayers: boolean;
  greedy: boolean;
  targetCompositionName?: string;
  excludeAdjustmentLayers?: boolean;
  excludeGuideLayers?: boolean;
  excludeDisabledLayers?: boolean;
};

export type PrefixAutoCreateTemplateDto = AutoCreateTemplateDto<AutoCreateTemplateType.prefix> & {
  prefixes?: string[];
  stripPrefix: boolean;
  targetCompositionName?: string;
  excludeAdjustmentLayers?: boolean;
  excludeGuideLayers?: boolean;
  excludeDisabledLayers?: boolean;
};

export type AnyAutoCreateTemplateDto = AllAutoCreateTemplateDto | PrefixAutoCreateTemplateDto;
