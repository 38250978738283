import { Key, useState } from 'react';
import classNames from 'classnames';
import { encode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import { SignalIcon as RenderingIcon } from '@heroicons/react/24/outline';
import { CheckIcon as SuccessIcon, XMarkIcon as ErrorIcon } from '@heroicons/react/24/solid';
import { ScriptAction } from '@plainly/types';
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonCopyCurl,
  CodeModal,
  CopyIdAction,
  CreatedByInformation,
  ErrorCard,
  FeatureGating,
  getJobError,
  HeaderWithBreadcrumbs,
  Loading,
  LoadingProps,
  NotFound,
  RenderCancelModal,
  RenderIntegrationsInfo,
  RenderOutputFormatInfo,
  RenderVideoInfo,
  RenderWebhookInfo,
  StyledA,
  StyledLink
} from '@src/components';
import {
  useBreakpoint,
  useDesignCurl,
  useFireRenderRetry,
  useGetRenderDetails,
  useNotifications,
  usePromoteRender,
  useQueryParams,
  useRenderCurl
} from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Asset, AssetType, ErrorCode, PlainlyPackage, Render, RenderState, Script, Scripting } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';
import { includesSafe, isEmptyObj } from '@src/utils';

import ScriptingDescription from '../project/template/layer/scripts/ScriptingDescription';

const LoadingRenderDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

type RenderModelProps = PropsWithModel<Render>;
type RenderInformationProps = PropsWithModel<Render> & { showError: boolean };

const isRenderingFinished = (render?: Render | null) =>
  render &&
  (render.state === RenderState.DONE ||
    render.state === RenderState.FAILED ||
    render.state === RenderState.INVALID ||
    render.state === RenderState.CANCELLED);

const isRenderCancelledOrInvalid = (render?: Render | null) =>
  render && includesSafe([RenderState.CANCELLED, RenderState.INVALID], render.state);

const RenderInformation = ({ model: render, showError: isWorkerError }: RenderInformationProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();

  const renderingFinished = isRenderingFinished(render);
  const batchId = render?.attributes?.batchRenderId;

  return (
    <>
      <Alert
        type="info"
        show={batchId !== undefined}
        alertContent={
          <div className="flex-1 md:flex md:justify-between">
            <p className="text-sm text-indigo-700">{t('components.render.RenderDetails.batchInformation')}</p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <Link
                to={withQueryParams(generatePath(routes.RENDER_BATCH_DOWNLOAD, { step: '2' }), {
                  batchRender: encode(JSON.stringify({ batchRenderId: batchId }))
                })}
                className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600"
              >
                {t('components.render.common.batchDownload')}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        }
      />
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="render-information-title" className="text-lg font-medium leading-6 text-gray-900">
            {t('components.render.RenderDetails.renderInformation')}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {render.publicDesign ? (
              <>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">{t('general.common.design')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <StyledLink to={generatePath(routes.DESIGN_DETAILS_URL, { designId: render.projectId })}>
                      {render.projectName}
                    </StyledLink>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">{t('components.common.variant')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">{render.templateName}</dd>
                </div>
              </>
            ) : (
              <>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">{t('components.common.project')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <StyledLink to={generatePath(routes.PROJECT_DETAILS, { id: render.projectId })}>
                      {render.projectName}
                    </StyledLink>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">{t('components.common.template')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <StyledLink
                      to={generatePath(routes.PROJECT_TEMPLATE_DETAILS, {
                        projectId: render.projectId,
                        templateId: render.templateId
                      })}
                    >
                      {render.templateName}
                    </StyledLink>
                  </dd>
                </div>
              </>
            )}
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('components.common.composition')}</dt>
              <dd className="mt-1 text-sm text-gray-900">{render.compositionName}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('general.common.submittedOn')}</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {localizationHelper.forDate().formatDateTimeStringLocally(render.createdDate)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('components.common.createdBy')}</dt>
              <dd className="mt-1 flex flex-col text-sm text-gray-900">
                <CreatedByInformation userId={render.createdBy} />
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('general.common.status')}</dt>
              <dd className="mt-1 flex text-sm capitalize text-gray-900">
                {!renderingFinished && <RenderingIcon className="mr-1.5 h-5 w-5 shrink-0 animate-spin text-gray-400" />}
                {`${
                  render.retried && !renderingFinished
                    ? t('components.render.common.retrying')
                    : t('components.render.common.renderState', { context: render.state?.toLocaleLowerCase() })
                }`}
              </dd>
              {renderingFinished && render.state === RenderState.FAILED && (
                <StyledA
                  className="cursor-pointer text-xs"
                  href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#rendering-issues`}
                  target="_blank"
                >
                  {t('general.action.troubleshootFailure')}
                </StyledA>
              )}
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.render.RenderDetails.expire', { context: `${render.expired || false}` })}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {localizationHelper.forDate().formatDateTimeStringLocally(render.expirationDate) || '-'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.render.RenderDetails.includeWatermark')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {render.options?.watermark ? t('general.common.yes') : t('general.common.no')}
              </dd>
            </div>
            {render.outputFormat?.attachmentFileName && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t('components.render.RenderDetails.fileNameTitle')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {t('components.render.RenderDetails.fileName', {
                    fileName: render.outputFormat.attachmentFileName,
                    ext: render.outputFormat.ext
                  })}
                </dd>
              </div>
            )}
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">{t('components.render.RenderDetails.renderId')}</dt>
              <dd>
                <CopyIdAction id={render.id} type="render" className="mt-1" />
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">{t('components.render.RenderDetails.parameters')}</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {isEmptyObj(render.parameters) ? (
                  t('components.render.RenderDetails.noParameters')
                ) : (
                  <p className="mt-2 w-full overflow-auto whitespace-pre rounded-md border border-gray-300 p-2 font-mono text-xs">
                    {JSON.stringify(render.parameters, null, 2)}
                  </p>
                )}
              </dd>
            </div>
            {!isWorkerError && render.error && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">{t('general.common.error')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <p className="mt-2 w-full overflow-auto whitespace-pre rounded-md border border-gray-300 p-2 font-mono text-xs">
                    {JSON.stringify(render.error, null, 2)}
                  </p>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  );
};

const getColorPreview = (colorValue: string) => {
  const values = Object.values(colorValue).map(c => Math.round(parseFloat(c) * 255));
  const rgb = `rgb(${values.toString()})`;
  return <div className="m-auto flex h-4 w-4 rounded-full" style={{ backgroundColor: rgb }}></div>;
};

const AssetRow = (props: {
  composition: React.ReactNode;
  layerName: React.ReactNode;
  type: React.ReactNode;
  property: React.ReactNode;
  value: React.ReactNode;
}) => (
  <tr>
    <td className="w-full max-w-0 px-3 py-4 text-sm text-gray-500 sm:w-auto sm:max-w-none">
      {props.composition}
      <dl className="font-normal lg:hidden">
        <dd className="mt-1 truncate text-gray-500">{props.layerName}</dd>
        <dt className="sr-only sm:hidden">{props.type}</dt>
        <dd className="mt-1 truncate text-gray-500 sm:hidden">{props.type}</dd>
      </dl>
    </td>
    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{props.layerName}</td>
    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{props.property}</td>
    <td className="max-w-xs px-3 py-4 text-left text-sm text-gray-500 sm:text-right">{props.value}</td>
  </tr>
);

const AssetInformation = ({ asset }: { asset: Asset }) => {
  const { t } = useTranslation();

  // data asset
  if (asset.type === AssetType.DATA) {
    return (
      <AssetRow
        composition={asset.composition}
        layerName={asset.layerName}
        type={asset.type}
        property={
          <>
            <span className="prose prose-sm">
              <code>{asset.property}</code>
            </span>
          </>
        }
        value={
          <>
            {asset.property === 'source.mainSource.color' && getColorPreview(asset.value)}
            {asset.property !== 'source.mainSource.color' &&
              (() => {
                switch (typeof asset.value) {
                  case 'number':
                    return t('components.render.ParametrizationDetails.propertyValue', {
                      value: localizationHelper
                        .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                        .format(asset.value),
                      context: asset.property
                    });
                  default:
                    return t('components.render.ParametrizationDetails.propertyValue', {
                      value: asset.value,
                      context: asset.property
                    });
                }
              })()}
          </>
        }
      />
    );
  }

  // media asset
  if (asset.type === AssetType.IMAGE || asset.type === AssetType.VIDEO || asset.type === AssetType.AUDIO) {
    return (
      <AssetRow
        composition={asset.composition}
        layerName={asset.layerName}
        type={asset.type}
        property={t('components.render.ParametrizationDetails.property', { context: asset.type })}
        value={
          <StyledA target="_blank" href={asset.src}>
            {t('general.action.view')}
          </StyledA>
        }
      />
    );
  }

  // script asset
  if (asset.type === AssetType.SCRIPT) {
    return (
      /* eslint-disable @typescript-eslint/no-explicit-any */
      <>
        {asset.parameters.map((param: any) => {
          return param.value.map((scriptResult: ScriptAction, index: Key) => {
            const script = { ...scriptResult, scriptType: scriptResult.type } as ScriptAction & Script;

            return (
              <AssetRow
                key={index}
                composition={scriptResult.compositionName}
                layerName={scriptResult.layerName}
                type={scriptResult.type}
                property={t('components.render.ParametrizationDetails.scriptName', { context: scriptResult.type })}
                value={<ScriptingDescription script={script} />}
              />
            );
          });
        })}
      </>
    );
  }

  return null;
};

const ParametrizationInformation = ({ model: render }: RenderModelProps) => {
  const { t } = useTranslation();

  const { parametrizationResults } = render;
  const [active, setActive] = useState<number | null>(null);

  const changeActiveParamResults = (index: number) => {
    active === index ? setActive(null) : setActive(index);
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="render-information-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.render.ParametrizationDetails.parametrization')}
        </h2>
      </div>
      <div className="border-t border-gray-200">
        <ul className="divide-y divide-gray-200">
          {parametrizationResults.map((param, index) => {
            const paramOk = !param.errorMessage && !param.fatalError && !param.mandatoryNotResolved;
            return (
              <li
                key={index}
                className={classNames(param.assets.length > 0 && 'cursor-pointer', index === active && 'bg-gray-50')}
              >
                <span
                  className="block hover:bg-gray-50"
                  onClick={() => param.assets.length > 0 && changeActiveParamResults(index)}
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="sm:w-2/5">
                        <p className="truncate text-sm font-medium text-gray-900">
                          {t('components.common.value', {
                            value: param.parametrization?.value,
                            default: param.parametrization?.defaultValue,
                            context: param.parametrization?.defaultValue && 'default'
                          })}
                        </p>
                        {param.parametrization && (
                          <p className="mt-2 flex items-center text-xs text-gray-500">
                            {param.parametrization.expression
                              ? t('components.common.dynamicValue', {
                                  context: param.parametrization?.mandatory && 'mandatory'
                                })
                              : t('components.common.staticValue')}
                          </p>
                        )}
                        <p
                          className={classNames(
                            'flex items-center text-xs text-gray-500',
                            param.parametrization && 'mt-2'
                          )}
                        >
                          {param.scripting?.scripts.length &&
                            t('components.render.ParametrizationDetails.scriptingCount', {
                              count: param.scripting?.scripts.length
                            })}
                        </p>
                      </div>
                      <div className="pt-3 sm:w-2/5 sm:pt-0">
                        {!paramOk && (
                          <div className="pt-3 md:pt-0">
                            {param.mandatoryNotResolved && (
                              <p className="flex items-center text-sm text-gray-500">
                                <span className="mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-red-400">
                                  <ErrorIcon className="h-3 w-3 text-white" />
                                </span>
                                {t('components.render.ParametrizationDetails.mandatoryNotResolved')}
                              </p>
                            )}
                            {param.fatalError && (
                              <p className="flex items-center text-sm text-gray-500">
                                <span className="mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-red-400">
                                  <ErrorIcon className="h-3 w-3 text-white" />
                                </span>
                                {t('components.render.ParametrizationDetails.fatalError')}
                              </p>
                            )}
                            {param.errorMessage && (
                              <p className="mt-2 flex items-center break-all text-sm text-gray-500">
                                {param.errorMessage}
                              </p>
                            )}
                          </div>
                        )}
                        {paramOk && (
                          <p className="flex items-center text-sm text-gray-500">
                            <span className="mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-green-400">
                              <SuccessIcon className="h-3 w-3 text-white" />
                            </span>
                            {t('components.render.common.success')}
                          </p>
                        )}
                      </div>
                      <div className="hidden md:block">
                        <p className="w-[68px] justify-center text-xs leading-5 text-gray-500">
                          {t('components.render.ParametrizationDetails.assetsCount', { count: param.assets.length })}
                        </p>
                      </div>
                    </div>
                    <div className="ml-3">
                      <svg
                        className={classNames(
                          'h-5 w-5',
                          index === active && 'rotate-90 transform',
                          param.assets.length > 0 ? 'text-gray-400' : 'text-gray-100'
                        )}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </span>
                <div
                  className={classNames('px-4 py-5 sm:px-6', index === active && 'block', index !== active && 'hidden')}
                >
                  {<ParametrizationInformationDetails assets={param.assets} scripting={param.scripting} />}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export type ParametrizationInformationDetailsProps = {
  assets: Asset[];
  scripting?: Scripting;
};

const ParametrizationInformationDetails = ({ assets }: ParametrizationInformationDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table className="min-w-full cursor-default divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {t('components.common.composition')}
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              {t('components.render.ParametrizationDetails.layerName')}
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              {t('components.render.ParametrizationDetails.property')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:text-right">
              {t('components.render.common.value')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white shadow sm:rounded-lg">
          {assets.map((asset, index) => (
            <AssetInformation asset={asset} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

type RenderPromoteCardProps = {
  renderId: string;
};

const RenderPromoteCard = ({ renderId }: RenderPromoteCardProps) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();

  const { isLoading, mutateAsync: promoteRender } = usePromoteRender();

  const promote = () => {
    promoteRender(renderId).then(() => {
      notifyInfo(t('components.render.RenderPromoteCard.promoteSuccess'));
    });
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.render.RenderPromoteCard.title')}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t('components.render.RenderPromoteCard.description')}</p>
            </div>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
            <FeatureGating
              enabledPackages={[PlainlyPackage.TEAM, PlainlyPackage.PRO]}
              freeTrialAllowed={true}
              message={t(`general.errorCode.${ErrorCode.FEATURE_GATING_NOT_ALLOWED_SHARING_LINKS}`)}
            >
              <Button onClick={promote} loading={isLoading}>
                {t('components.render.RenderPromoteCard.promote')}
              </Button>
            </FeatureGating>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResubmitRenderAction = ({ model: render }: RenderModelProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyInfo } = useNotifications();

  const { isLoading, mutateAsync: retryRender } = useFireRenderRetry();

  const show = render && render.state === RenderState.FAILED;

  if (show) {
    const doRetry = () => {
      retryRender(render.id).then(response => {
        if (!response) {
          return;
        }

        notifyInfo(t('components.render.RenderDetails.resubmitNotification'));
        const path = generatePath(routes.RENDER_DETAILS, { id: response.id });
        navigate(path);
      });
    };

    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('components.render.RenderDetails.resubmit')}
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>{t('components.render.RenderDetails.resubmitInfo')}</p>
              </div>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
              <Button disabled={isLoading} loading={isLoading} onClick={doRetry}>
                {t('components.render.RenderDetails.resubmit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

type ThumbnailUrisProps = {
  thumbnailUris: string[] | undefined;
};

export const ThumbnailUris = ({ thumbnailUris }: ThumbnailUrisProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2 className="text-lg font-medium leading-6 text-gray-900">{t('components.render.common.thumbnails')}</h2>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
          {thumbnailUris?.map((uri, index) => (
            <StyledA key={index} className="break-all text-sm" href={uri} target="_blank">
              {t('components.render.RenderDetails.thumbnail', { count: index + 1 })}
            </StyledA>
          ))}
        </div>
      </div>
    </div>
  );
};

const CopyCurlCard = ({ model: render }: RenderModelProps) => {
  const { t } = useTranslation();
  const isDesign = render.publicDesign;

  const renderToDesign = (render: Render) => {
    return {
      designId: render.projectId,
      variantId: render.templateId,
      parameters: render.parameters,
      options: render.options,
      webhook: render.webhook,
      outputFormat: render.outputFormat
    };
  };
  const { curlWithApiKey: renderCurlWithApiKey, curlWithoutApiKey: renderCurlWithoutApiKey } = useRenderCurl({
    ...render
  });
  const { curlWithApiKey: designCurlWithApiKey, curlWithoutApiKey: designCurlWithoutApiKey } = useDesignCurl(
    renderToDesign(render)
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {t('components.render.common.renderUsingApi')}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{t('components.render.RenderDetails.curlCardDescription')}</p>
        </div>
        <div className="mt-5 flex justify-end">
          <Button onClick={() => setShowModal(true)} secondary className="mr-2">
            {t('components.render.RenderDetails.showCurl')}
          </Button>
          <ButtonCopyCurl
            curlWithApiKey={isDesign ? designCurlWithApiKey : renderCurlWithApiKey}
            curlWithoutApiKey={isDesign ? designCurlWithoutApiKey : renderCurlWithoutApiKey}
            alwaysVisible
          />
        </div>
      </div>
      <CodeModal
        size="lg"
        content={isDesign ? designCurlWithoutApiKey : renderCurlWithoutApiKey}
        showModal={showModal}
        showCopyAction
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export type RenderDetailsProps = {
  renderId: string;
};

export const RenderDetails = ({ renderId }: RenderDetailsProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();
  const [showCancelRenderModal, setShowCancelRenderModal] = useState(false);
  const { isLoading, data: render } = useGetRenderDetails(renderId);

  const renderingFinished = isRenderingFinished(render);
  const renderForceStopped = isRenderCancelledOrInvalid(render);

  let rerenderOutputFormat = render?.outputFormat;
  // change outputFormat postEncoding, if captions was used to its baseEncoding (happens only on Render again)
  if (rerenderOutputFormat?.postEncoding?.type === 'captions') {
    rerenderOutputFormat = {
      ...rerenderOutputFormat,
      postEncoding: rerenderOutputFormat.postEncoding.baseEncoding
    };
  }

  const rerenderPath = withQueryParams(routes.RENDER_FORM, {
    projectId: render?.projectId,
    templateId: render?.templateId,
    rerenderParams: !isEmptyObj(render?.parameters) && encode(JSON.stringify(render?.parameters)),
    rerenderOptions: encode(
      JSON.stringify({
        outputFormat: rerenderOutputFormat,
        webhook: render?.webhook,
        options: render?.options
      })
    )
  });
  const showRerender =
    render?.state === RenderState.DONE ||
    render?.state === RenderState.FAILED ||
    render?.state === RenderState.INVALID ||
    render?.state === RenderState.CANCELLED;
  const showParametrizationResults = render?.parametrizationResults && render.parametrizationResults.length > 0;
  const showThumbnails = !render?.expired && render?.thumbnailUris && render.thumbnailUris.length > 0;
  const showPromoteRender = render?.state === RenderState.DONE && !render.expired && !render.publicDesign;

  const jobError = render?.error && getJobError(render.error);

  const isLarge = useBreakpoint('lg');

  return (
    <>
      {isLoading && <LoadingRenderDetails title={t('components.render.RenderDetails.loading')} />}
      {!isLoading && (
        <>
          {!render && <NotFound title={t('components.render.RenderDetails.empty')} />}
          {render && (
            <div className="space-y-6">
              <HeaderWithBreadcrumbs
                breadcrumbs={
                  <Breadcrumb>
                    <BreadcrumbItem to={routes.RENDERS_LIST} label={t('general.common.renders')} />
                    <BreadcrumbItem
                      to={withQueryParams(routes.RENDERS_LIST, { projectId: render.projectId })}
                      label={`${render.projectName}`}
                    />
                    <BreadcrumbItem
                      to={withQueryParams(routes.RENDERS_LIST, {
                        projectId: render.projectId,
                        templateId: render.templateId
                      })}
                      label={`${render.templateName}`}
                    />
                  </Breadcrumb>
                }
                header={t('components.common.renderDetails')}
                actions={
                  <>
                    {includesSafe([RenderState.PENDING, RenderState.THROTTLED, RenderState.QUEUED], render.state) && (
                      <Button secondary className="mr-2" onClick={() => setShowCancelRenderModal(true)}>
                        {t('components.render.common.cancelRender')}
                      </Button>
                    )}
                    {render.state === RenderState.DONE && render.projectZipUrl && (
                      <a href={render.projectZipUrl} rel="noreferrer">
                        <Button secondary className="mr-2">
                          {t('general.action.downloadProject')}
                        </Button>
                      </a>
                    )}
                    {showRerender &&
                      (render.publicDesign ? (
                        <>
                          <Link
                            to={withQueryParams(
                              generatePath(routes.DESIGN_DETAILS_URL, { designId: render?.projectId }),
                              {
                                render: 'true',
                                variantId: render?.templateId,
                                rerenderParams:
                                  !isEmptyObj(render?.parameters) && encode(JSON.stringify(render?.parameters)),
                                rawRender: 'true',
                                rerenderOptions: encode(
                                  JSON.stringify({
                                    outputFormat: render.outputFormat,
                                    webhook: render.webhook,
                                    options: render.options
                                  })
                                )
                              }
                            )}
                          >
                            <Button>{t('components.render.RenderDetails.rerender')}</Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={rerenderPath}>
                            <Button>{t('components.render.RenderDetails.rerender')}</Button>
                          </Link>
                        </>
                      ))}
                  </>
                }
              />
              <div className="mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-2 lg:col-start-1 lg:row-start-1">
                  {jobError && (
                    <section aria-labelledby="render-error">
                      <ErrorCard error={jobError} />
                    </section>
                  )}
                  {!isLarge && <RenderVideoInfo renderingFinished={renderingFinished} render={render} />}
                  <section aria-labelledby="render-information-title" className="space-y-6">
                    <RenderInformation model={render} showError={jobError ? true : false} />
                    <ResubmitRenderAction model={render} />
                    {showPromoteRender && <RenderPromoteCard renderId={render.id} />}
                    {showParametrizationResults && <ParametrizationInformation model={render} />}
                  </section>
                </div>
                <div className="space-y-6 lg:col-span-1 lg:col-start-3 lg:row-start-1">
                  {isLarge && <RenderVideoInfo renderingFinished={renderingFinished} render={render} />}
                  <CopyCurlCard model={render} />
                  {render.outputFormat && (
                    <section>
                      <RenderOutputFormatInfo outputFormat={render.outputFormat} />
                    </section>
                  )}
                  {showThumbnails && (
                    <section aria-labelledby="thumbnail-uris">
                      <ThumbnailUris thumbnailUris={render.thumbnailUris} />
                    </section>
                  )}
                  {render.webhook && (
                    <section aria-labelledby="render-webhook-title">
                      <RenderWebhookInfo
                        model={render}
                        canRetrigger={renderingFinished && !renderForceStopped ? true : false}
                      />
                    </section>
                  )}
                  {render.options?.integrations && (
                    <section aria-labelledby="render-integrations-title">
                      <RenderIntegrationsInfo integrations={render.options.integrations} />
                    </section>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <RenderCancelModal
        showModal={showCancelRenderModal}
        setShowModal={setShowCancelRenderModal}
        renderId={renderId}
      />
    </>
  );
};
