import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ArrowDownTrayIcon, PlayIcon } from '@heroicons/react/20/solid';
import { Button, InfiniteList, InfiniteTable, StyledLink } from '@src/components';
import localizationHelper from '@src/i18n';
import { Render, RenderState } from '@src/models';
import * as routes from '@src/routes';

export const getRenderStateBadge = (state: RenderState | undefined, retried: boolean, t: TFunction) => {
  const stateDisplay = t('components.render.common.renderState', { context: state?.toLocaleLowerCase() });

  if (
    retried &&
    state !== RenderState.DONE &&
    state !== RenderState.FAILED &&
    state !== RenderState.INVALID &&
    state !== RenderState.CANCELLED
  ) {
    return (
      <span className="inline-flex animate-pulse-tailwind items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
        {t('components.render.common.retrying')}
      </span>
    );
  }

  switch (state) {
    case RenderState.PENDING:
    case RenderState.THROTTLED:
    case RenderState.QUEUED:
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
          {stateDisplay}
        </span>
      );
    case RenderState.IN_PROGRESS:
      return (
        <span className="inline-flex animate-pulse-tailwind items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
          {stateDisplay}
        </span>
      );
    case RenderState.DONE:
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
          {stateDisplay}
        </span>
      );
    case RenderState.CANCELLED:
      return (
        <span className="inline-flex items-center rounded-full bg-stone-100 px-2.5 py-0.5 text-xs font-medium text-stone-800">
          {stateDisplay}
        </span>
      );
    case RenderState.FAILED:
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
          {stateDisplay}
        </span>
      );
    case RenderState.INVALID:
      return (
        <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
          {stateDisplay}
        </span>
      );
  }
};

export type RendersTableProps = {
  renders: Render[][] | undefined | null;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isSuccess: boolean;
};

export const RendersTable = ({ renders, hasNextPage, fetchNextPage, isSuccess }: RendersTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* DESKTOP */}
      <div className="hidden sm:block">
        <div className="mt-2 flex flex-col">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <InfiniteTable
              data={renders}
              headerRow={<RendersTableHeaderRow t={t} />}
              renderItem={(model: Render) => <RendersTableRow key={model.id} renderItem={model} t={t} />}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isSuccess={isSuccess}
              onClick={(model: Render) => navigate(generatePath(routes.RENDER_DETAILS, { id: model.id }))}
            />
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="sm:hidden">
        <InfiniteList
          data={renders}
          renderItem={(model: Render) => <RendersListItem key={model.id} renderItem={model} t={t} />}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isSuccess={isSuccess}
        />
      </div>
    </>
  );
};

const RendersTableHeaderRow = ({ t }: { t: TFunction }) => (
  <tr>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('components.common.template')}
    </th>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('components.common.project')}
    </th>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('general.common.status')}
    </th>
    <th className="hidden bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:table-cell">
      {t('general.common.submittedOn')}
    </th>
    <th className="hidden bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:table-cell">
      {t('components.render.RendersTable.thExpire')}
    </th>
    <th className="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"></th>
    <th className="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"></th>
  </tr>
);

type RenderItemProps = {
  renderItem: Render;
  t: TFunction;
};

const RendersTableRow = ({ renderItem, t }: RenderItemProps) => {
  const hasAttachment = renderItem.outputFormat?.attachment;

  return (
    <>
      <td className="max-w-0 truncate whitespace-nowrap px-6 py-4 text-sm text-gray-900">{renderItem.templateName} </td>
      <td className="max-w-0 truncate whitespace-nowrap px-6 py-4 text-sm text-gray-500">{renderItem.projectName}</td>
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
        {getRenderStateBadge(renderItem.state, renderItem.retried || false, t)}
      </td>
      <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 lg:table-cell">
        <time>{localizationHelper.forDate().formatDistanceToNowWithDateString(renderItem.createdDate, true)}</time>
      </td>
      <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 lg:table-cell">
        <time>{localizationHelper.forDate().formatDistanceToNowWithDateString(renderItem.expirationDate, true)}</time>
      </td>
      <td className="w-0 whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
        {renderItem.state === RenderState.DONE && (
          <a href={renderItem.output} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
            <Button
              small
              secondary
              icon={hasAttachment ? <ArrowDownTrayIcon className="h-5 w-5" /> : <PlayIcon className="h-5 w-5" />}
              disabled={renderItem.expired}
              className="w-full justify-center"
            >
              {hasAttachment ? t('general.action.download') : t('components.common.preview')}
            </Button>
          </a>
        )}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <StyledLink to={generatePath(routes.RENDER_DETAILS, { id: renderItem.id })} onClick={e => e.stopPropagation()}>
          {t('components.render.RendersTable.details')}
        </StyledLink>
      </td>
    </>
  );
};

const RendersListItem = ({ renderItem, t }: RenderItemProps) => {
  const navigate = useNavigate();

  const stateDisplay = t('components.render.common.renderState', {
    context: renderItem.state?.toLocaleLowerCase()
  });

  const hasAttachment = renderItem.outputFormat?.attachment;

  return (
    <div
      className={classNames('block cursor-pointer bg-white px-4 py-4 hover:bg-gray-50')}
      onClick={() => navigate(generatePath(routes.RENDER_DETAILS, { id: renderItem.id }))}
    >
      <span className="flex items-center space-x-4">
        <span className="flex flex-1 space-x-2 truncate">
          <span className="flex w-full flex-col truncate text-sm text-gray-500">
            <span className="flex w-full justify-between">
              <span className="flex truncate text-gray-800">{renderItem.templateName}</span>
              <time>
                {localizationHelper.forDate().formatDistanceToNowWithDateString(renderItem.createdDate, true)}
              </time>
            </span>
            <span>{renderItem.projectName}</span>
            <span className="text-xs">
              {renderItem.state !== RenderState.DONE && stateDisplay}
              <a href={renderItem.output} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
                <Button
                  small
                  secondary
                  className={classNames('float-right rounded-full', renderItem.state !== RenderState.DONE && 'hidden')}
                  disabled={renderItem.expired || renderItem.state !== RenderState.DONE}
                  icon={hasAttachment ? <ArrowDownTrayIcon className="h-5 w-5" /> : <PlayIcon className="h-5 w-5" />}
                >
                  {hasAttachment ? t('general.action.download') : t('components.common.preview')}
                </Button>
              </a>
            </span>
          </span>
        </span>
      </span>
    </div>
  );
};
