import { useTranslation } from 'react-i18next';

import { Alert } from '@src/components/common';
import { AnyVideoRevision, RenderState, RevisionState } from '@src/models';

export const RevisionStateInfos = ({
  revision,
  className,
  message
}: {
  revision?: AnyVideoRevision;
  className?: string;
  message?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!revision && (
        <Alert
          type="info"
          alertContent={t('components.article.ArticleVideo.common.revisionInProgress')}
          show
          className={className}
        />
      )}
      {revision && (
        <>
          <Alert
            type="error"
            show={revision.state === RevisionState.ERROR}
            alertContent={message ? message : t('components.article.ArticleVideo.common.revisionError')}
            className={className}
          />
          <Alert
            type="info"
            alertContent={t('components.article.ArticleVideo.common.revisionInProgress')}
            show={revision.state === RevisionState.PROCESSING && !revision.finalRender && !revision.previewRender}
            className={className}
          />
          {revision.finalRender && (
            <Alert
              type="info"
              alertContent={t('components.article.ArticleVideo.common.finalRenderInProgress')}
              show={
                revision.state === RevisionState.PROCESSING &&
                [RenderState.PENDING, RenderState.THROTTLED, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(
                  revision.finalRender.renderState
                )
              }
              className={className}
            />
          )}
          {revision.previewRender && (
            <Alert
              type="info"
              alertContent={t('components.article.ArticleVideo.common.previewRenderInProgress')}
              show={
                revision.state === RevisionState.PROCESSING &&
                [RenderState.PENDING, RenderState.THROTTLED, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(
                  revision.previewRender.renderState
                )
              }
              className={className}
            />
          )}
        </>
      )}
    </>
  );
};
